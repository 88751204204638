import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Container, Header, Footer } from 'rsuite';
import { Heading } from 'rsuite';


export class Home extends Component {
  render() {
    return (
      <div className="App" style={{
        backgroundImage: "url(Images/backInicio.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}>
        <div className="App container">
          <br></br>
          <center>
            <Image src={`Images/Logo.png`} width={"150px"} height={"150px"} />
          </center>
          <br></br>
          <React.Fragment>
            <Container>
              <Header style={{ backgroundColor: "#f9f7f6", height: "auto", padding: "20px", borderRadius: "15px" }}>
                <center>
                  <Heading level={1}>
                    CARRERA "LA ZANGANERA"
                  </Heading>
                </center>
                <Heading level={4}>
                  IMPORTANTE: Antes de inciar tu registro:
                </Heading>
                <p></p>
                <Heading level={4}>
                  • Foto del participante
                  <br />
                  • Foto de su voucher de pago
                  <br />
                  <b>
                    • Deposita el costo de la carrera en la siguiente cuenta:
                  </b>
                  <br />
                  <br />
                  Banco: <b>BBVA</b>
                  <br />
                  Beneficiario: ALONDRA DANIELA GODINEZ VAZQUEZ
                  <br />
                  Tarjeta: <b>4152 3142 0325 0900</b>
                  <br />
                  Cuenta: <b>150 28 93184</b>
                  <br />
                  Clabe: <b>0127 0001 5028 9318 45</b>
                  <br />
                  <br />
                  <p>Recuerda tomar foto de tu voucher para el registro.</p>
                  <h6>
                    * IMPORTANTE: En el concepto de la transferencia anota tu
                    NOMBRE COMPLETO, para una identificación más rapida.
                  </h6>
                  <p></p>
                </Heading>
              </Header>
              <Footer>
                <br></br>
                <Link className="btn btn-primary w-100" to="/Registro">
                  Iniciar Registro
                </Link>
                {/* <NavLink className="btn btn-primary w-100" to="/registro">
                  Iniciar Registro
                </NavLink>
                <Routes>
                  <Route path="/registro" element={<Registro />} />
                </Routes> */}
              </Footer>
              <br></br>
            </Container>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
