import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { Home } from "./Entities/Home";
import { Registro } from "./Entities/Registro";
import { Confirmado } from "./Entities/Confirmado";
import { Login } from "./Entities/Login/Login";
import { ConsultaRegistrados } from "./Entities/Consulta/ConsultaRegistrados";
import "rsuite/dist/rsuite.min.css";
import { Provider } from "react-redux";
import store from "./redux/Store";


function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/registro/confirmado/:curp" Component={Confirmado} /> 
          <Route path="/registro" Component={Registro} />
          <Route path="/Login" Component={Login} />
          <Route path="/Login/consultaregistrados" Component={ConsultaRegistrados} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
