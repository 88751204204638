import React, { Component } from "react";
import { Image, Col, Row, FormCheck } from "react-bootstrap";
import {
  Container,
  Header,
  Heading,
  Radio,
  RadioGroup,
  SelectPicker,
  InputNumber,
  Button,
  Input,
  DateInput,
  Text,
  Badge,
  Message,
  toaster,
} from "rsuite";
import "../Entities/style.css";
import axios from "../axiosIn";
import { variables } from "../Variables";

export class Registro extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();

    this.state = {
      idLiga: 1,
      tipoInsc: "1",
      tipoReg: "1",
      categoria: "",
      categorias: [{ label: "", value: "" }],
      tallas: [
        { label: "S - Chica", value: "S - Chica" },
        { label: "M - Mediana", value: "M - Mediana" },
        { label: "L - Grande", value: "L - Grande" },
        { label: "Extra grande", value: "XL - Extra Grande" },
        { label: "Doble Ex Grande", value: "XXL - Doble Ex Gde." },
      ],
      dataRegistro: [
        { label: "Individual", value: "1" },
        { label: "Por Grupo", value: "2" },
      ],
      formaPagos: [
        {
          label: "Transferencia electrónica de fondos",
          value: "03 - Transferencia electrónica de fondos",
        },
        { label: "Efectivo", value: "01 - Efectivo" },
      ],
      disabledReg: true,
      fileFoto: null,
      fileComprob: null,
      showCard1: "none",
      showCard2: "none",
      errNombre: "",
      errEquipo: "",
      errFecNac: "",
      errCurp: "",
      errProc: "",
      errCelular: "",
      errForma: "",
      errCategoria: "",
      errComprob: "",
      errFoto: "",
      num: 1,
      disTalla: true,
      precio: 0,
      resultCurpOk: "none",
      resultCurpErr: "none",
      showLabelPrice: "none",
      lblPrecio: "El precio por inscripción es de ",
      colorCurp: "#4caf50",
      msjValidacion: "",
      error: "",
      msjOk: "Se ha registrado, de forma correcta.",
      displayMsjOk: "none",
      disabledOk: false,
      disabledFoto: false,
      curpValidada: false,
      disabledComprob: false,
      curp: ""
    };
  }

  focusInput = () => {
    this.myRef.current.focus();
  };

  async loadListaCategorias() {
    await axios
      .get("Registro/ListaCategorias")
      .then((res) => {
        let _categos = [{ label: "", value: "" }];
        res.data.map((c) =>
          _categos.push({
            label: `${c.NomCategoria} (${c.IdCategoria})`,
            value: c.IdCategoria,
          })
        );
        this.setState({ categorias: _categos });
      })
      .catch((err) => console.error(err));
  }

  componentDidMount() {
    this.loadListaCategorias();
  }

  validEntity(values) {
    var nombre = values.nombre;
    var equipo = values.equipo;
    var foto = values.foto;
    var _categoria = values.categoria;
    var fecNac = values.fecNac;
    var curp = values.curp;
    var celular = values.celular;
    var procedencia = values.procedencia;

    var errors = false;

    if (!nombre) {
      this.setState({ errNombre: "* Este campo es requerido" });
      errors = true;
    } else {
      this.setState({ errNombre: "" });
    }

    if (!equipo) {
      this.setState({ errEquipo: "* Este campo es requerido" });
      errors = true;
    } else {
      this.setState({ errEquipo: "" });
    }

    if (!_categoria) {
      this.setState({ errCategoria: "* Este campo es requerido" });
      errors = true;
    } else {
      this.setState({ errCategoria: "" });
    }

    if (!fecNac) {
      this.setState({ errFecNac: "* Este campo es requerido" });
      errors = true;
    } else {
      this.setState({ errFecNac: "" });
    }

    if (!curp) {
      this.setState({ errCurp: "* Este campo es requerido" });
      errors = true;
    } else {
      this.setState({ errCurp: "" });
    }

    if (!procedencia) {
      this.setState({ errProc: "* Este campo es requerido" });
      errors = true;
    } else {
      this.setState({ errProc: "" });
    }

    if (!celular) {
      this.setState({ errCelular: "* Este campo es requerido" });
      errors = true;
    } else {
      this.setState({ errCelular: "" });
    }

    return errors;
  }

  async saveEntity(values) {
    var _comprob = values.comprobante;
    var foto = values.foto;
    var _formPago = values.formaPago;
    var _validate = this.validEntity(values);

    console.log("Validate::: ", _validate);
    if (_validate) {
      this.setState({ disabledOk: false });
      return;
    }

    if (!_formPago) {
      this.setState({ errForma: "Debe seleccionar una forma de pago" });
      this.setState({ disabledOk: false });
      return;
    } else {
      this.setState({ errForma: "" });
    }

    if (
      _formPago !== "01 - Efectivo" &&
      this.state.fileComprob === null &&
      this.state.tipoInsc !== "4"
    ) {
      this.setState({ errComprob: "Debe adjuntar el pago" });
      this.setState({ disabledOk: false });
      return;
    } else {
      this.setState({ errComprob: "" });
    }

    if (!this.state.disabledFoto && foto.size === 0) {
      this.setState({ disabledOk: false });
      this.setState({ errFoto: "Debe adjuntar una fotografía" });
      return;
    }

    if (!this.state.curpValidada) {
      this.toast("El formato de la curp no es valido.", "error");
      this.setState({ disabledOk: false });
      return;
    }

    console.log("Grabando...");
    fetch(variables.API_URL + "Registro/SaveEntity", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        nombre: values.nombre,
        equipo: values.equipo,
        categoria: values.categoria,
        curp: values.curp,
        fecNac: values.fecNac,
        formaPago: this.state.tipoInsc === "4" ? "Sin Pago" : values.formaPago,
        foto: !this.state.disabledFoto ? values.foto.name : "SinFoto.jpg",
        comprobante:
          this.state.fileComprob !== null ? this.state.fileComprob.name : "",
        celular: values.celular,
        procedencia: values.procedencia,
        tipoInsc: this.state.tipoInsc,
        talla: values.talla,
      }),
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result === -3) {
            this.toast("La CURP que intenta registrar, ya existe.", "warning");
            this.setState({ disabledOk: false });
            return;
          }
          if (result !== "") {
            console.log("Saved");
            this.saveFiles(result, values.formaPago);
          } else {
            this.setState({ disabledOk: false });
            alert("Valide la información, datos incompletos.");
          }
        },
        (error) => {
          this.setState({ disabledOk: false });
          alert("Ocurrio un error");
        }
      );
  }

  async calcularPrecio() {
    const abortController = new AbortController();
    var _idLiga = this.state.idLiga;
    var _tipoInsc = this.state.tipoInsc;
    var _tipoReg = this.state.tipoReg;
    var _num = this.state.num;

    var url = `Registro/GetFinalPrice/${_idLiga}/${_tipoInsc}/${_tipoReg}/${_num}`;

    try {
      const response = await axios.get(url, { signal: abortController.signal });
      this.setState({ showLabelPrice: "block" });
      this.setState({ precio: response.data.precioFin });
      var _precio = response.data.precioFinal;
      this.setState({ lblPrecio: `${response.data.msj}${_precio}` });
    } catch (error) {
      if (abortController.signal.aborted) {
        console.log("Data fetching cancelled");
      } else {
        // Handle error
        this.toast("Ha ocurrido un error", "error");
      }
    }
  }

  handleChange = (event) => {
    this.setState({ tipoReg: event });
    if (event === "2") {
      this.setState({ disabledReg: false });
    } else {
      this.setState({ disabledReg: true });
    }
  };

  handleChangeTipoInsc = (event) => {
    this.setState({ tipoInsc: event.target.value });
    this.setState({ showCard1: "none" });
    this.setState({ showCard2: "none" });
    if (event.target.value === "4") {
      this.setState({ disabledComprob: true });
      this.toast(
        "El registro será validado de acuerdo a la información ingresada.",
        "info"
      );
    } else {
      this.setState({ disabledComprob: false });
    }
  };

  handleChangeNombre = (event) => {
    this.setState({ nombre: event.target.value });
  };

  handleFecNac = (date, event) => {
    this.setState({ fecNac: date });
  };

  clickComenzar = () => {
    this.calcularPrecio();
    if (this.state.tipoInsc === "1") {
      this.setState({ disTalla: true });
      this.setState({ showCard1: "block" });
      setTimeout(() => {
        this.focusInput();
      }, 300);
    } else {
      if (this.state.tipoInsc === "2") {
        this.setState({ disTalla: false });
      } else {
        this.setState({ disTalla: true });
      }
      this.setState({ showCard1: "block" });
      setTimeout(() => {
        this.focusInput();
      }, 300);
    }
    setTimeout(() => {
      this.focusInput();
    }, 300);
  };

  handleFileFoto = (e) => {
    this.setState({ fileFoto: e.target.files[0] });
  };

  handleFileComprob = (e) => {
    this.setState({ fileComprob: e.target.files[0] });
  };

  errorStyles = (errorVisible) => {
    return {
      display: errorVisible ? "block" : "none",
      color: "red",
      marginTop: 6,
    };
  };

  curpValida = (curp) => {
    var re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = curp.match(re);

    this.setState({ resultCurpOk: "block" });

    if (!validado) {
      //Coincide con el formato general?
      this.setState({ msjValidacion: "Formato: Incorrecto" });
      this.setState({ curpValidada: false });
      this.setState({ colorCurp: "red" });
      return false;
    } else {
      this.setState({ msjValidacion: "Formato: Valido" });
      this.setState({ curpValidada: true });
      this.setState({ colorCurp: "#4caf50" });
    }

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (var i = 0; i < 17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - (lngSuma % 10);
      if (lngDigito === 10) return 0;
      return lngDigito;
    }

    console.log("Validado TRUE");
    if (validado[2] !== digitoVerificador(validado[1])) return false;

    return true; //Validado
  };

  async saveFiles(curp, pago) {
    const formData = new FormData();
    formData.append("comprobante", this.state.fileComprob);
    formData.append("foto", this.state.fileFoto);
    formData.append("curp", curp);
    formData.append("forma", pago);

    await axios
      .post(variables.API_URL + "Registro/SaveFiles", formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log("Saved files");
        this.setState({ displayMsjOk: "block" });
        window.location = `/#/Registro/Confirmado/curp=${curp}`;
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    var data = new FormData(event.target);
    let formObject = Object.fromEntries(data.entries());

    this.setState({ disabledOk: true });
    this.saveEntity(formObject);
  };

  clikConFoto(event) {
    this.setState({ disabledFoto: !event.target.checked });
    if (!event.target.checked) {
      this.setState({ errFoto: "" });
    }
  }

  handleInputCurp = (e) => {
    var newValue = e.target.value.toUpperCase();
    this.setState({ curp: newValue });
  }

  toast(msg, type = "info") {
    toaster.push(
      <Message showIcon type={type} closable>
        <strong>{type}!</strong>
        &nbsp; {msg}
      </Message>,
      { duration: 5000, placement: "topCenter" }
    );
  }

  render() {
    const {
      tipoInsc,
      tipoReg,
      disabledReg,
      disTalla,
      tallas,
      showCard1,
      fileComprob,
      fileFoto,
      formaPagos,
      categorias,
      dataRegistro,
      resultCurpOk,
      lblPrecio,
      showLabelPrice,
      num,
      colorCurp,
      msjValidacion,
      errNombre,
      errEquipo,
      errFecNac,
      errCurp,
      errProc,
      errCelular,
      errFoto,
      errForma,
      errCategoria,
      errComprob,
      msjOk,
      displayMsjOk,
      disabledOk,
      disabledFoto,
      curpValidada,
      disabledComprob,
      curp
    } = this.state;

    return (
      <>
        <div
          style={{
            backgroundImage: "url(Images/backLogin.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: '800px'
          }}
        >
          <div className="App">
            <div className="App container">
              <Container>
                <Header
                  style={{
                    backgroundColor: "#f9f7f6",
                    padding: "20px",
                    borderRadius: "15px",
                  }}
                >
                  <Row>
                    <Col className="col-lg-3 col-md-3 col-sm-3">
                      <Image
                        src={`Images/Logo.png`}
                        width={"120px"}
                        height={"120px"}
                      />
                    </Col>
                    <Col className="col-lg-9 col-md-9 col-sm-9">
                      <Heading level={2}>Registro de Participantes</Heading>
                      <p className="lead">
                        DOMINGO 10 DE NOVIEMBRE, <b>CARRERA LA ZANGANERA</b>, LA
                        COMUNIDAD DE PALOMAS, Villa de Reyes S.L.P.
                      </p>
                      <p className="lead">
                        FINAL DE CAMPEONATO INTERMUNICIPAL MTB 2024, LICIVILLA
                        2024
                      </p>
                    </Col>
                  </Row>
                </Header>
                <br></br>
                <React.Fragment>
                  <form onSubmit={this.handleSubmit}>
                    <Container>
                      <>
                        <div className="card" style={{ padding: "10px" }}>
                          <div className="card-body">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6">
                                <b>Seleccione el Tipo de Inscripción</b>
                                <RadioGroup
                                  name="opTipoInsc"
                                  inline
                                  defaultValue={tipoInsc}
                                >
                                  <Radio
                                    value="1"
                                    onClick={this.handleChangeTipoInsc}
                                    checked
                                  >
                                    Solo Inscripción = $250.00
                                  </Radio>
                                  <Radio
                                    value="4"
                                    onClick={this.handleChangeTipoInsc}
                                  >
                                    Invitado Especial
                                  </Radio>
                                  <Radio
                                    value="3"
                                    onClick={this.handleChangeTipoInsc}
                                  >
                                    Especial parejas $400.00
                                  </Radio>
                                </RadioGroup>
                              </div>
                              &nbsp;
                              <div className="col-lg-2 col-md-2 col-sm-2">
                                <b>Registro de participante</b>
                                <SelectPicker
                                  id="tipoInsc"
                                  name="tipoInsc"
                                  data={dataRegistro}
                                  searchable={false}
                                  style={{ width: "100%" }}
                                  placeholder="Selecciona"
                                  defaultValue={tipoReg}
                                  onChange={this.handleChange}
                                  disabled
                                />
                              </div>
                              &nbsp;
                              <div className="col-lg-2 col-md-2 col-sm-2">
                                <b>Participantes</b>
                                <InputNumber
                                  id="num"
                                  name="num"
                                  defaultValue={num}
                                  min={1}
                                  disabled={disabledReg}
                                  style={{ textAlign: "center" }}
                                />
                              </div>
                              &nbsp;
                              <div className="col-lg-1 col-md-1 col-sm-1">
                                <br></br>
                                <Button
                                  appearance="primary"
                                  style={{ width: "100%" }}
                                  onClick={this.clickComenzar}
                                >
                                  Comenzar
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div
                          className="card"
                          id="card1"
                          style={{ padding: "10px", display: `${showCard1}` }}
                        >
                          <div
                            className="card-body"
                            ref={this.myRef}
                            tabIndex={0}
                          >
                            <div className="row mb-3">
                              <div className="col-lg-4 col-md-4 col-sm-4">
                                <b>Nombre</b>
                                <Input
                                  placeholder="Nombre"
                                  name="nombre"
                                  id="nombre"
                                />
                                {errNombre && (
                                  <p style={{ color: "red" }}>{errNombre}</p>
                                )}
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2">
                                <b>Equipo</b>
                                <Input
                                  placeholder="Nombre del equipo"
                                  id="equipo"
                                  name="equipo"
                                />
                                {errEquipo && (
                                  <p style={{ color: "red" }}>{errEquipo}</p>
                                )}
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3">
                                <b>Categoría</b>
                                <SelectPicker
                                  id="categoria"
                                  name="categoria"
                                  data={categorias}
                                  searchable
                                  style={{ width: "100%" }}
                                  placeholder="Selecciona"
                                />
                                {errCategoria && (
                                  <p style={{ color: "red" }}>{errCategoria}</p>
                                )}
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3">
                                <b>Fecha de nacimiento</b>
                                <DateInput
                                  format="dd/MM/yyyy"
                                  id="fecNac"
                                  name="fecNac"
                                />
                                {errFecNac && (
                                  <p style={{ color: "red" }}>{errFecNac}</p>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-lg-3 col-md-3 col-sm-3">
                                <b>CURP</b>
                                <Input
                                  placeholder="CURP"
                                  id="curp"
                                  name="curp"
                                  maxLength={18}
                                  onChange={this.curpValida}
                                  onInput={this.handleInputCurp}
                                  value={curp}
                                />
                                {errCurp && (
                                  <p style={{ color: "red" }}>{errCurp}</p>
                                )}
                                <Badge
                                  style={{
                                    background: `${colorCurp}`,
                                    color: "white",
                                    width: "100%",
                                    display: `${resultCurpOk}`,
                                  }}
                                >
                                  {msjValidacion}
                                </Badge>
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-4">
                                <b>Adjuntar comprobante de pago</b>
                                <input
                                  type="file"
                                  id="comprobante"
                                  name="comprobante"
                                  accept=".jpg"
                                  onChange={this.handleFileComprob}
                                  disabled={disabledComprob}
                                />
                                <Text
                                  color="red"
                                  size="sm"
                                  style={{ display: `${showLabelPrice}` }}
                                >
                                  {lblPrecio}
                                </Text>
                                {errComprob && (
                                  <p style={{ color: "red" }}>{errComprob}</p>
                                )}
                              </div>
                              <div className="col-lg-4 col-md-4 col-sm-4">
                                <FormCheck
                                  type="checkbox"
                                  defaultChecked
                                  id="conFoto"
                                  label="Adjuntar foto"
                                  style={{ fontWeight: 800 }}
                                  onChange={(e) => this.clikConFoto(e)}
                                />
                                <input
                                  type="file"
                                  id="foto"
                                  name="foto"
                                  accept=".jpg"
                                  disabled={disabledFoto}
                                  onChange={this.handleFileFoto}
                                />
                                {errFoto && (
                                  <p style={{ color: "red" }}>{errFoto}</p>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div
                                className="col-lg-2 col-md-2 col-sm-2"
                                style={{ display: "none" }}
                              >
                                <b>Talla</b>
                                <SelectPicker
                                  id="talla"
                                  name="talla"
                                  data={tallas}
                                  searchable
                                  style={{ width: "100%" }}
                                  placeholder="Selecciona"
                                  disabled={disTalla}
                                />
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3">
                                <b>Procedencia</b>
                                <Input
                                  id="procedencia"
                                  name="procedencia"
                                  placeholder="Procedencia"
                                />
                                {errProc && (
                                  <p style={{ color: "red" }}>{errProc}</p>
                                )}
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2">
                                <b>Número de celular</b>
                                <Input
                                  id="celular"
                                  name="celular"
                                  placeholder="Celular"
                                  maxLength={12}
                                />
                                {errCelular && (
                                  <p style={{ color: "red" }}>{errCelular}</p>
                                )}
                              </div>
                              <div className="col-lg-3 col-md-3 col-sm-3">
                                <b>Forma de pago</b>
                                <SelectPicker
                                  data={formaPagos}
                                  id="formaPago"
                                  name="formaPago"
                                  style={{ width: 300 }}
                                  searchable={false}
                                  placeholder="Selecciona"
                                />
                                {errForma && (
                                  <p style={{ color: "red" }}>{errForma}</p>
                                )}
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-1">
                                <br></br>
                                <Button
                                  appearance="primary"
                                  color="green"
                                  style={{ width: "100%" }}
                                  type="submit"
                                  disabled={disabledOk}
                                >
                                  Enviar
                                </Button>
                              </div>
                            </div>
                            <div
                              className="row"
                              style={{ display: `${displayMsjOk}` }}
                            >
                              <div className="col">
                                <center>
                                  <Message showIcon type="info" centered>
                                    {msjOk}
                                  </Message>
                                </center>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    </Container>
                  </form>
                </React.Fragment>
              </Container>
            </div>
          </div>
        </div>
      </>
    );
  }
}
