import {
  ADD_PARTICIPANT,
  GET_CATEGORY_LIST,
  SAVE_FILE1,
  SAVE_FILE2
} from '../actions/registro-actions';

const initialState = [];

const RegistroReducer = function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORY_LIST: {
      return [...action.payload];
    }
    case ADD_PARTICIPANT: {
      return [...action.payload];
    }
    case SAVE_FILE1: {
      return [...action.payload];
    }
    case SAVE_FILE2: {
      return [...action.payload]
    }
    default: {
      return [...state];
    }
  }
};

export default RegistroReducer;
