import React, { Component } from "react";
import { Header, Heading } from "rsuite";
import Datatable from "./Datatable";
import { Input, InputGroup } from "rsuite";
import SearchIcon from "@rsuite/icons/Search";
import { SelectPicker, Button, Divider } from "rsuite";
import columns from "./Columns";
import columnsMob from "./ColumnsMobile";
import axios from "../../axiosIn";
import moment from "moment/moment";

export class ConsultaRegistrados extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estatus: "Sin Validar",
      setFiltering: "",
      rows: [],
      disabledBtn: false,
      loading: false,
      dataEstatus: [
        { label: "Sin Validar", value: "SIN VALIDAR" },
        { label: "Validados", value: "VALIDADO" },
        { label: "Rechazados", value: "RECHAZADO" },
        { label: "Descargados", value: "DESCARGADA" },
      ],
    };
    this.validSession();
  }

  async loadData() {
    this.setState({ loading: true });
    this.setState({ disabledBtn: true });
    var url = `Registro/GetParticipantes/${this.state.estatus}`;
    await axios
      .get(url)
      .then((res) => {
        console.log(res.data);
        this.setState({ loading: false });
        this.setState({ rows: res.data });
        this.setState({ disabledBtn: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.error(err);
        this.setState({ disabledBtn: false });
      });
  }

  handleChangeEstat = (e) => {
    this.setState({ estatus: e });
  };

  getCookie = (name) => {
    const cookies = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`));

    return cookies ? cookies.split("=")[1] : null;
  };

  validSession() {
    var sessionID = this.getCookie("sessionID");
    var expira = this.getCookie("expira");
    var dateIsAfter = moment(new Date()).isAfter(moment(expira));

    if (sessionID.length === 0) {
      window.location = `/#/Login`;
      window.location.reload();
    }

    if (expira.length > 0) {
      if (dateIsAfter) {
        window.location = `/#/Login`;
        window.location.reload();
      }
    }
  }

  deleteCookies = () => {
    document.cookie = `sessionID=;`;
    document.cookie = "expira=;";
  };

  cerrarSesion() {
    this.deleteCookies();
    window.location = `/#/Login`;
  }

  getResolution(){
    var widthRes = window.screen.availWidth;
    console.log('Resol::', widthRes);
  }

  render() {
    const { setFiltering, disabledBtn, loading } = this.state;
    this.validSession();
    this.getResolution();
    return (
      <main className="flex min-h-screen items-center justify-center p-3">
        <Header
          style={{
            backgroundColor: "#f9f7f6",
            height: "auto",
            padding: "20px",
            borderRadius: "15px",
          }}
        >
          <div class="col">
            <Heading>Consulta de Participantes</Heading>
          </div>
        </Header>
        <div className="row">
          <div className="flex min-h-screen items-center justify-center p-4">
            <h6>Filtros de búsqueda</h6>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-4">
                <InputGroup inside>
                  <Input
                    onChange={(e) => this.setState({ setFiltering: e })}
                    placeholder="Escribe los valores a buscar..."
                    autoFocus
                  />
                  <InputGroup.Addon>
                    <SearchIcon />
                  </InputGroup.Addon>
                </InputGroup>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2">
                <SelectPicker
                  data={this.state.dataEstatus}
                  searchable={true}
                  defaultValue={"SIN VALIDAR"}
                  style={{ width: "100%" }}
                  onChange={this.handleChangeEstat}
                  placeholder="Selecciona una opción"
                />
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1">
                <Button
                  appearance="primary"
                  className="w-100"
                  loading={loading}
                  disabled={disabledBtn}
                  onClick={() => this.loadData()}
                >
                  Buscar
                </Button>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1">
                <Button
                  appearance="ghost"
                  className="w-100"
                  onClick={() => this.cerrarSesion()}
                >
                  Cerrar sesión
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="flex min-h-screen items-center justify-center p-4">
            <Datatable
              columns={columns}
              rows={this.state.rows}
              filtering={setFiltering}
            />
          </div>
        </div>
        <Divider style={{ fontSize: "11px" }}>Designed by RADYXXA</Divider>
      </main>
    );
  }
}
