import {
  LuCheck,
  LuTrash,
  LuSearch,
  LuDownload,
  LuAlertCircle,
} from "react-icons/lu";
import axios from "../../axiosIn";
import { variables } from "../../Variables";
import { toaster, Message } from "rsuite";


const handleValidated = async (id, auth) => {
  var url = `${variables.API_URL}Registro/Validar/${id}/${auth}`;
  await axios
    .get(url)
    .then((response) => {
      if (response.data === 1) {
        toast("El registro se actualizo de forma correcta", "info");
				window.location.reload();
      }
    })
    .catch((err) => console.error(err));
};

const toast = (msg, type = "info") => {
  toaster.push(
    <Message showIcon type={type} closable>
      <strong>{type}!</strong>
      &nbsp; {msg}
    </Message>,
    { duration: 5000, placement: "topCenter" }
  );
};


const download = async (id) => {
  var url = `${variables.API_URL}Registro/download/${id}`;
  await axios
    .get(url)
    .then((response) => {
      var element = document.createElement("a");
      element.href = url;
      element.download = "Comprobante.jpg";
      element.click();
    })
    .catch((err) => console.error(err));
};

const showImage = async (id, estatus) => {
  var url = `${variables.API_URL}Registro/ValidaDirectorio/${id}`;
  await axios
    .get(url)
    .then((response) => {
      if (response.data != "0") {
        var curp = response.data.curp;
        var file = response.data.docPago;
        if (estatus === "DESCARGADA" || estatus === "VALIDADO" || estatus === 'RECHAZADO') {
          file = response.data.foto;
        }
        var url = `${variables.PHOTO_URL}${curp}/${file}`;
        window.open(
          url,
          "_blank",
          "height=500,width=400,left=100,status=yes,toolbar=no,menubar=no,location=no,directories=no"
        );
      } else {
        alert(
          "El directorio no existe, conctacte al Administrador del sistema"
        );
      }
    })
    .catch((err) => console.error(err));
};

const columns = [
  {
    header: "#",
    accessorKey: "id",
  },
  {
    header: "Nombre",
    accessorKey: "nombre",
  },
  {
    header: "Equipo",
    accessorKey: "equipo",
  },
  // {
  //   header: "Fecha",
  //   accessorKey: "fecha",
  // 	cell: info => DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED).toUpperCase()
  // },
  {
    header: "Categoría",
    accessorKey: "categoria",
  },
  {
    header: "Clave",
    accessorKey: "clave",
    cell: (info) => (
      <div style={{ textAlign: "center" }}>{info.renderValue()}</div>
    ),
  },
  {
    header: "CURP",
    accessorKey: "curp",
  },
  {
    header: "Forma de pago",
    accessorKey: "formaPago",
  },
  {
    header: "Cobrado",
    accessorKey: "cobrado",
    cell: (info) => (
      <div style={{ textAlign: "right" }}>{info.renderValue()}</div>
    ),
  },
  {
    header: "Doc. Pago",
    accessorKey: "docPago",
    cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {row.getValue("docPago") === "" ? (
          ""
        ) : (
          <>
            <a
              onClick={() => download(row.getValue("id"))}
              style={{ color: "blue", cursor: "pointer" }}
            >
              <LuDownload />
            </a>
            <a>
              &nbsp; &nbsp;{" "}
              <a
                onClick={() => showImage(row.getValue("id"))}
                style={{ color: "blue", cursor: "pointer" }}
              >
                <LuSearch />
              </a>
            </a>
          </>
        )}
      </div>
    ),
  },
  {
    header: "Foto",
    accessorKey: "foto",
    cell: ({ row }) => (
      <div style={{ textAlign: "center" }}>
        {row.getValue("foto") === "" ? (
          ""
        ) : row.getValue("statusInscrip") !== "SIN VALIDAR" ? (
          <>
            <a
              onClick={() => download(row.getValue("id"))}
              style={{ color: "blue", cursor: "pointer" }}
            >
              <LuDownload />
            </a>
            &nbsp; &nbsp;{" "}
            <a
              onClick={() =>
                showImage(row.getValue("id"), row.getValue("statusInscrip"))
              }
              style={{ color: "blue", cursor: "pointer" }}
            >
              <LuSearch />
            </a>
          </>
        ) : (
          <LuAlertCircle title="Debes validar el registro" />
        )}
      </div>
    ),
  },
  {
    header: "Estatus",
    accessorKey: "statusInscrip",
  },
  {
    header: "Acción",
    accessorKey: "accion",
    cell: ({ row }) => (
      <>
        {row.getValue("statusInscrip") === "SIN VALIDAR" ? (
          <div style={{ textAlign: "center" }}>
            <button
              className="btn btn-outline"
              onClick={() => handleValidated(row.getValue("id"), 1)}
            >
              <LuCheck style={{ color: "green" }} />
            </button>
            <button
              className="btn btn-outline"
              onClick={() => handleValidated(row.getValue("id"), 0)}
            >
              <LuTrash style={{ color: "red" }} />
            </button>
          </div>
        ) : (
          <LuCheck />
        )}
      </>
    ),
  },
];

export default columns;
