import React, { Component } from "react";
import {
  Container,
  Message,
  Content,
  Form,
  Button,
  Panel,
  Input,
  Stack,
  VStack,
  InputGroup,
} from "rsuite";
import { FaCodepen, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import AvatarIcon from "@rsuite/icons/legacy/Avatar";
import TextImageIcon from "@rsuite/icons/TextImage";
import { variables } from "../../Variables";
import axios from "../../axiosIn";
import moment from "moment/moment";
import uuid from 'react-uuid';


const errorStyles = (errorVisible) => {
  return {
    display: errorVisible ? "block" : "none",
    color: "red",
    marginTop: 6,
  };
};

const Password = React.forwardRef((props, ref) => {
  const [visible, setVisible] = React.useState(false);

  const handleChange = () => {
    setVisible(!visible);
  };
  return (
    <InputGroup inside ref={ref} {...props}>
      <Input placeholder="Contraseña" type={visible ? "text" : "password"} />
      <InputGroup.Button onClick={handleChange}>
        {visible ? <FaRegEye /> : <FaRegEyeSlash />}
      </InputGroup.Button>
    </InputGroup>
  );
});

export class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errVisCodNeg: false,
      errVisUSer: false,
      errorVisible: false,
      errorMessage: "",
      errMsjCodNeg: "",
      errMsjUser: "",
      codNeg: "",
      user: "",
      pwd: "",
      display: "none",
      _loading: false,
    };
  }

  getJSessionId() {
    var jsId = document.cookie.match(/JSESSIONID=[^;]+/);
    if (jsId != null) {
      if (jsId instanceof Array) jsId = jsId[0].substring(11);
      else jsId = jsId.substring(11);
    }
    return jsId;
  }

  setCookie = (name, value, hours) => {
    document.cookie = `${name}=${value};`;
    document.cookie = `expira=${moment().add(hours, "h").format()};`;
    document.cookie = `sessionID=${crypto.randomUUID()};`
  };

  getCookie = (name) => {
    const cookies = document.cookie
      .split("; ")
      .find((row) => row.startsWith(`${name}=`));

    return cookies ? cookies.split("=")[1] : null;
  };

  validUsuario = async () => {
    var errors = false;

    if (!this.state.pwd) {
      this.setState({ errorVisible: true });
      this.setState({
        errorMessage: "Debes escribir una contraseña",
      });
      errors = true;
    }
    if (!this.state.codNeg) {
      this.setState({ errVisCodNeg: true });
      this.setState({ errMsjCodNeg: "Debes escribir un código de negocio" });
      errors = true;
    }
    if (!this.state.user) {
      this.setState({ errVisUSer: true });
      this.setState({ errMsjUser: "Debes escribir un usuario" });
      errors = true;
    }
    if (errors) {
      return;
    }

    this.setState({ errMsjCodNeg: "" });
    this.setState({ errMsjUser: "" });
    this.setState({ errorMessage: "" });
    this.setState({ _loading: true });
    var _login = "Login";
    var url = `${variables.API_URL}${_login}`;
    var data = JSON.stringify({
      codNegocio: this.state.codNeg,
      user: this.state.user,
      pwd: this.state.pwd.target.value,
    });

    await axios
      .post(url, data)
      .then((res) => {
        this.setState({ _loading: false });
        if (res.data === 0 || res.data === -1) {
          this.setState({ display: "block" });
          return;
        } else {
          this.setState({ display: "none" });
        }
        this.setCookie("user", this.state.user, 10);
        window.location = `/#/Login/ConsultaRegistrados`;
      })
      .catch((err) => {
        this.setState({ _loading: false });
        console.error(err);
      });
  };

  handleInput = (e) => {
    var newValue = e.target.value.toUpperCase();
    this.setState({ codNeg: newValue });
    this.setState({ errMsjCodNeg: "" });
  };

  render() {
    const {
      errorMessage,
      errMsjCodNeg,
      errMsjUser,
      _loading,
      user,
      pwd,
      codNeg,
      display,
    } = this.state;
    return (
      <div
        style={{
          backgroundImage: "url(Images/backLogin.jpg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <div className="row px-2">&nbsp;</div>
          <br></br>
          <Content>
            <Stack
              alignItems="center"
              justifyContent="center"
              style={{ height: "100%", padding: "10px" }}
            >
              <Panel
                header="Inicio de sesión"
                bordered
                style={{ width: 400, padding: "10px" }}
              >
                <Form fluid>
                  <Form.Group>
                    <Form.ControlLabel>
                      <b>Código de Equipo</b>
                    </Form.ControlLabel>
                    <InputGroup inside>
                      <Form.Control
                        name="codigo"
                        placeholder="Código de Equipo"
                        autoFocus
                        onInput={this.handleInput}
                        value={codNeg}
                        errorMessage={errMsjCodNeg}
                        errorPlacement="bottomStart"
                      />
                      <InputGroup.Addon>
                        <TextImageIcon />
                      </InputGroup.Addon>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>
                      <b>Usuario</b>
                    </Form.ControlLabel>
                    <InputGroup inside>
                      <Form.Control
                        name="user"
                        onChange={(e) => this.setState({ user: e })}
                        placeholder="Usuario"
                        errorMessage={errMsjUser}
                        errorPlacement="bottomStart"
                      />
                      <InputGroup.Addon>
                        <AvatarIcon />
                      </InputGroup.Addon>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group>
                    <Form.ControlLabel>
                      <b>Contraseña</b>
                    </Form.ControlLabel>
                    <Form.Control
                      name="password"
                      autoComplete="off"
                      onChange={(e) => this.setState({ pwd: e })}
                      accepter={Password}
                      errorMessage={errorMessage}
                      placeholder="Contraseña"
                    />
                  </Form.Group>
                  <Message
                    showIcon
                    style={{ display: `${display}` }}
                    type="warning"
                    header="Datos invalidos"
                  >
                    Por favor valide su información, no es posible iniciar
                    sesión.
                  </Message>
                  <br></br>
                  <VStack spacing={10}>
                    <Button
                      appearance="primary"
                      block
                      onClick={() => this.validUsuario()}
                      loading={_loading}
                    >
                      Entrar
                    </Button>
                    <a href="#">Registro de Participantes</a>
                  </VStack>
                  <br></br>
                  <VStack alignItems="center">
                  <a style={{fontSize: '12px'}} href="https://www.radyxxa.com.mx">Designed by RADYXXA</a>
                  </VStack>
                </Form>
              </Panel>
            </Stack>
            <div className="row px-2" style={{ height: "300px" }}>
              &nbsp;
            </div>
          </Content>
        </Container>
      </div>
    );
  }
}
