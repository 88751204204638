import axios from "axios";

// baseURL: "https://api-mtb.master-planner.net/api/",
// baseURL: "https://api-mtbdev.master-planner.net/api/", 
// baseURL: "https://localhost:7214/api/",
 
const axiosInstance = axios.create({
  baseURL: "https://api-mtbdev.master-planner.net/api/", 
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstance.defaults.headers.post["Content-Type"] = "application/json";

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) ||
        "Error de conexión, algo salio mal!"
    )
);

export default axiosInstance;
