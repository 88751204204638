import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { DateTime } from "luxon";
import { useState } from "react";
import { LuArrowLeftToLine } from "react-icons/lu";
import { LuArrowLeft } from "react-icons/lu";
import { LuArrowRight } from "react-icons/lu";
import { LuArrowRightToLine } from "react-icons/lu";


const Datatable = ({ rows, columns, filtering }) => {
  const data = rows;

  const [sorting, setSorting] = useState([]);
  const [setFiltering] = useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });


  return (
    <>
      <div className="w3-container w3-responsive">
        <div className="row mb-2">
          <br></br>
          <table className="w3-table-all w3-hoverable w3-card-4">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="w3-light-gray"
                style={{ cursor: "pointer" }}
              >
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    {
                      { asc: "⬆️", desc: "⬇️" }[
                        header.column.getIsSorted() ?? null
                      ]
                    }
                  </th>
                ))}
              </tr>
            ))}
            <tbody style={{ color: "black" }}>
              {table.getRowModel().rows.length === 0 ? (
                <tr>
                  <td colSpan={12} style={{ textAlign: "center" }}>
                    No se encontrarón registros
                  </td>
                </tr>
              ) : (
                table.getRowModel().rows.map((row) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
          <br></br>
          <div className="float-end">
            <button
              onClick={() => table.setPageIndex(0)}
              className="btn btn-secondary-outline"
              style={{ fontSize: "18px" }}
            >
              <LuArrowLeftToLine />
            </button>
            <button
              disabled={!table.getCanPreviousPage()}
              onClick={() => table.previousPage()}
              className="btn btn-secondary-outline"
              style={{ fontSize: "18px" }}
            >
              <LuArrowLeft />
            </button>
            <button
              disabled={!table.getCanNextPage()}
              onClick={() => table.nextPage()}
              className="btn btn-secondary-outline"
              style={{ fontSize: "18px" }}
            >
              <LuArrowRight />
            </button>
            <button
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              className="btn btn-secondary-outline"
              style={{ fontSize: "18px" }}
            >
              <LuArrowRightToLine />
            </button>
          </div>
          <br></br>
        </div>
      </div>
    </>
  );
};

export default Datatable;
