import axios from "axios";

export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";

export const ADD_PARTICIPANT = "ADD_PARTICIPANT";
export const SAVE_FILE1 = "SAVE_FILE1";
export const SAVE_FILE2 = "SAVE_FILE2";
export const GET_CATEGORY_LIST_ERROR = "GET_CATEGORY_LIST_ERROR;"


export const getCategoryList = () => (dispatch) => {
  axios.get("/api/Registro/ListaCategorias").then((res) => {
    dispatch({
      type: GET_CATEGORY_LIST,
      payload: res.data,
    });
  });
};

export const getCategoryListError = error => ({
  type: GET_CATEGORY_LIST_ERROR,
  payload: { error }
});

export const addParticipant = (values) => (dispatch) => {
  axios.post("/api/Registro", { values }).then((res) => {
    console.log(res.data);
    dispatch({
      type: ADD_PARTICIPANT,
      payload: res.data,
    });
  });
};


export const saveFile1 = () => (dispatch) => {
  axios.post("/api/Registro/SaveFile1", { }).then((res) => {
    console.log(res.data);
    dispatch({
      type: SAVE_FILE1,
      payload: res.data,
    });
  });
};
