import React, { Component } from "react";
import { Image } from "react-bootstrap";
import { CartaResponsiva } from "../Entities/CartaResponsiva";
import { Container, Header, Footer, Heading, Button } from "rsuite";
import { Link } from "react-router-dom";
import html3pdf from "html3pdf";
import axios from "../axiosIn";

const options = {
  filename: "CartaResponsiva.pdf",
  FontFace: "Arial",
  margin: 0,
  image: { type: "jpeg", quality: 0.98 },
  html2canvas: { scale: 1 },
  jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
};

const _window = window.location.href;
let _param = [];
_param = _window.split("=");
let _curp = "";

if (_param.length > 1) {
  _curp = _param[1].trim();
}

export class Confirmado extends Component {
  constructor(props) {
    super(props);

    this.myRef = React.createRef();
    this.state = {
      disabledBtn: false,
      curp: _curp,
    };
  }

  componentDidMount() {
    this.setState({ refer: this.myRef.current });
  }

  convertToPdf = () => {
    const content = this.myRef.current;
    html3pdf().set(options).from(content).save();
    this.saveDescarga();
  };

  async saveDescarga() {
    if (!_curp) {
      return;
    }

    axios
      .put("Registro/UpdateCartaResp", {
        curp: _curp,
      })
      .then((response) => {
        if (response.data === 1) {
          console.log("OK");
        }
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { disabledBtn } = this.state;
    return (
      <div className="App">
        <div className="App container">
          <center>
            <Image src={`Images/Logo.png`} width={"150px"} height={"150px"} />
          </center>
          <br></br>
          <React.Fragment>
            <Container>
              <Header
                style={{
                  backgroundColor: "#f9f7f6",
                  height: "auto",
                  padding: "20px",
                  borderRadius: "15px",
                }}
              >
                <div class="col">
                  <Heading>!Gracias por tu registro!</Heading>
                  <br />
                  <Heading>
                    Te esperamos en esta gran carrera, el próximo: 10 de
                    noviembre de 2024
                  </Heading>
                </div>
              </Header>
              <br></br>
              <Link className="btn btn-primary w-100" to="/Registro">
                Iniciar Nuevo Registro
              </Link>
              <br></br>
              <div ref={this.myRef}>
                <CartaResponsiva />
              </div>
              <Footer>
                <br></br>
                <Button
                  appearance="primary"
                  style={{ width: "100%" }}
                  onClick={this.convertToPdf}
                  disabled={disabledBtn}
                >
                  Descargar Carta Responsiva
                </Button>
              </Footer>
              <br></br>
            </Container>
          </React.Fragment>
        </div>
      </div>
    );
  }
}
