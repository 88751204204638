import React, { Component } from "react";
import { Image } from "react-bootstrap";
import axios from "../axiosIn";
import { toaster, Message } from "rsuite";

export class CartaResponsiva extends Component {
  constructor(props) {
    super(props);

    const _window = window.location.href;
    let _param = [];
    _param = _window.split("=");
    const _curp = _param[1].trim();

    this.state = {
      fecha: "",
      nombre: "",
      categoria: "",
      fecNac: "",
      edad: 0,
      clave: "",
      curp: _curp,
      equipo: "",
      numero: 0,
      tel: "",
    };
    this.getDataCurp(_curp);
  }

  async getDataCurp(curp) {
    const abortController = new AbortController();

    var url = `Registro/GetData/${curp}`;

    try {
      const response = await axios.get(url, { signal: abortController.signal });
      this.setState({ fecha: response.data.fecha });
      this.setState({ nombre: response.data.nombre });
      this.setState({ categoria: response.data.categoria });
      this.setState({ equipo: response.data.equipo });
      this.setState({ edad: response.data.edad });
      this.setState({ clave: response.data.clave });
      this.setState({ numero: response.data.numero });
      this.setState({ tel: response.data.tel });
      this.setState({ fecNac: response.data.fecNac });
    } catch (error) {
      if (abortController.signal.aborted) {
        console.log("Data fetching cancelled");
      } else {
        // Handle error
        this.toast("Ha ocurrido un error", "error");
      }
    }
  }

  toast(msg, type = "info") {
    toaster.push(
      <Message showIcon type={type} closable>
        <strong>{type}!</strong>
        &nbsp; {msg}
      </Message>,
      { duration: 5000, placement: "topCenter" }
    );
  }

  render() {
    const {
      fecha,
      nombre,
      categoria,
      fecNac,
      edad,
      clave,
      curp,
      equipo,
      numero,
      tel,
    } = this.state;

    return (
      <>
        <br></br>
        <div
          className="App container table-responsive"
          style={{ border: "solid 1px" }}
        >
          <div className="row mb-2">&nbsp;</div>
          <div className="row">
            <center>
              <Image
                src={`Images/Logo1.jpg`}
                width={"100px"}
                height={"100px"}
              />
            </center>
          </div>
          <div className="row mb-1">&nbsp;</div>
          <div className="row mb-2">
            <div className="col">
              <b>VILLA DE REYES, S.L.P. A </b>&nbsp; {fecha}
            </div>
          </div>
          <br></br>
          <div className="row mb-2">
            <div className="col">
              <table style={{ width: "100%" }} className="table">
                <tr>
                  <td style={{ width: "10%" }}>
                    <b>NOMBRE:</b>
                  </td>
                  <td style={{ borderBottom: "solid 1px", width: "28%" }}>
                    {nombre}
                  </td>
                  <td style={{ width: "5%" }}>
                    <b>EDAD:</b>
                  </td>
                  <td style={{ borderBottom: "solid 1px" }}>{edad}</td>
                  <td>
                    <b>EQUIPO:</b>
                  </td>
                  <td style={{ borderBottom: "solid 1px", width: "25%" }}>
                    {equipo}
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>CATEGORÍA</b>
                  </td>
                  <td style={{ borderBottom: "solid 1px" }}>{categoria}</td>
                  <td>
                    <b>CLAVE:</b>
                  </td>
                  <td style={{ borderBottom: "solid 1px" }}>{clave}</td>
                  <td>
                    <b>NUMERO:</b>
                  </td>
                  <td style={{ borderBottom: "solid 1px" }}>{numero}</td>
                </tr>
                <tr>
                  <td>
                    <b>FEC. NAC:</b>
                  </td>
                  <td style={{ borderBottom: "solid 1px" }}>{fecNac}</td>
                  <td>
                    <b>CURP:</b>
                  </td>
                  <td style={{ borderBottom: "solid 1px" }}>{curp}</td>
                  <td>
                    <b>TEL:</b>
                  </td>
                  <td style={{ borderBottom: "solid 1px" }}>{tel}</td>
                </tr>
                <tr>
                  <td colSpan={5}>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={5}>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    __________________________________________________________
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    NOMBRE Y FIRMA DEL CORREDOR(A) <br></br>DEL PADRE O TUTOR EN
                    CASO DE SER MENOR DE EDAD
                  </td>
                </tr>
                <tr>
                  <td colSpan={5}>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={5}>&nbsp;</td>
                </tr>
                <tr>
                  <td colSpan={6}>
                    <b>EXONERACIÓN DEL EVENTO - CARRERA LA ZANGANERA</b>{" "}
                    <br></br>
                    Entiendo que en todo evento o actividad deportiva existen
                    riesgos, por ello tengo la firme intención de ser
                    responsable de mí mismo, de mi cuidado personal, de mis
                    actitudes y de mis conductas dentro del evento. Absuelvo al
                    Comité Organizador de este evento o actividad, de toda
                    responsabilidad derivadas de enfermedad, alergias, lesiones
                    y daños que pueda sufrir como resultado de mi participación,
                    tanto en traslados como en los espacios e instalaciones
                    utilizados en el evento. También doy fe de que estoy en
                    condiciones físicas adecuadas, tengo suficiente preparación
                    para participar y mi condición de salud ha sido verificada
                    recientemente por un médico. No obstante, doy mi
                    consentimiento para que mis familiares, tomen las medidas y
                    hagan los arreglos que consideren necesarios y convenientes
                    para mi salud y mi bienestar, si se presentara
                    alguna contingencia.
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </>
    );
  }
}
